<template>
  <div class="login">
    <!-- 登录header -->
    <header class="login__header clearfix">
      <div class="title pull-left" >{{projectName}}</div>

      <!--<div class="right pull-right">
        <img src="../module/component/frame/img/avatar.png" alt class="img" />

        <span>登录</span>
      </div>-->
    </header>

    <!-- 登录 form -->
    <el-form class="login__content" ref="form" :model="form" :rules="rules" size="-">
      <p class="user-title">用户登录</p>

      <el-form-item prop="username">
        <el-input
                class="set-height"
                v-model.trim="form.username"
                prefix-icon="el-icon-user"
                placeholder="用户名"
                style="width: 100%" />
      </el-form-item>

      <el-form-item prop="password">
        <el-input
                class="set-height"
                type="password"
                v-model.trim="form.password"
                prefix-icon="el-icon-lock"
                placeholder="密码"
                style="width: 100%" />
      </el-form-item>

      <el-form-item prop="identifyCode">
        <el-input
                class="set-height"
                v-model.trim="form.identifyCode"
                placeholder="验证码"
                style="width: 60%" />
        <el-image @click="changeCode" class="identifyImage" :src="url" ></el-image>
        
        <!-- 错误提示信息 -->
        <div class="el-form-item__error" v-show="errorMsg">{{errorMsg}}</div>
      </el-form-item>
  
      <el-form-item>
        <el-button
                class="login-btn set-height"
                :loading="loginLoading"
                @click="login"
                style="width: 100%"
        >登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { setToken } from "@/assets/js/auth";
  export default {
    data() {
      return {
        form: {
          username: "",
          password: "",
          identifyCode: ""
        },
        rules: {
          username: { required: true, message: "请输入用户名", trigger: "blur" },
          password: { required: true, message: "请输入密码", trigger: "blur" },
          identifyCode: { required: true, message: "请输入验证码", trigger: "blur"}
        },
        codeurl: 'api/identifyImage.jpg',
        url: '',
        errorMsg: "",
        loginLoading: false,
        projectName: ""
      };
    },

    created:function(){
		//登录添加键盘事件,注意,不能直接在焦点事件上添加回车
		document.onkeydown = (e) => {
			let key = window.event.keyCode;
			if (key === 13){
			  this.login();//方法
			}
		}
    },

    mounted() {
      this.changeCode();
      this.$post('/queryProjectName').then((m) => {
        this.projectName = m.content.projectName;
      });

    },

    methods: {
      login() {
        this.loginLoading = true;
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.$post('/weblogin', this.form ).then((m) => {
              this.loginLoading = false;
              var type = m.content.type;
              if(type == 'error') {
                this.errorMsg = "用户名密码错误！";
                return false;
              }else if(type == 'nobody') {
                this.errorMsg = "用户不存在！";
                return false;
              }else if(type == 'codeerror'){
                this.errorMsg = "验证码错误！";
                this.changeCode();
                return false;
              }
              let result = JSON.parse(m.content.content);
              sessionStorage.clear();
              setToken(result.access_token);
              if(m.content.type == "G") {
                this.$router.push({path: "/Administrator"}); //管理员
                sessionStorage.setItem("roleType",'G');
              }else if(m.content.type == "P") {
                this.$router.push({path: "/Judge"}); //评委
              }else if(m.content.type == "T") {
                this.$router.push({path: "/School"}); //学校
              }else if(m.content.type == "K") {
                this.$router.push({path: "/Administrator"}); //管理员
                sessionStorage.setItem("roleType",'K');
              }
            }, (err) => {

            }).catch(err => {
              this.loginLoading = false;

            })
          } else {
            this.loginLoading = false;
            return false;
          }
        });
      },
      changeCode(){
        this.url = this.codeurl + "?" + Math.random();
      },
    }
  };
</script>

<style lang="scss" scoped>
  $header-height: 70px;
  .login {
    min-width: 1200px;
    height: 100vh;
    background-color: $--color-primary;
    overflow: auto;
  }

  .login__header {
    height: $header-height;
    line-height: $header-height;
    padding: 0 $--frame-gutter;
    background-color: #fff;

    .title {
      font-size: 19px;
      font-weight: bold;
    }

    .right {
      color: #666;
      .img {
        width: 36px;
        height: 36px;
        margin-right: 8px;
      }
    }
  }

  .login__content {
    width: 20%;
    margin: 6% auto;

    .user-title {
      font-size: 36px;
      color: #fff;
      margin-bottom: 50px;
      text-align: center;
    }

    .login-btn {
      color: $--color-primary;
    }

    .set-height {
      height: 50px;

      /deep/ .el-input__inner {
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .identifyImage{
    width: 35%;
    margin-left: 5%; 
    position: absolute;
    height: 48px;
  }
</style>
